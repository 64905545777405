<template>
  <v-dialog
    v-model="show"
    max-width="700"
    persistent
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
      >
        <v-toolbar-title>Remove Offer</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <p class="title font-weight-medium">
          Are you sure?
        </p>
        <template v-if="openRequests > 0">
          <p>
            Removing the offer will cancel the
            <span class="font-weight-bold">{{ openRequests }}</span>
            open request(s).
          </p>
          You cannot undo this action. If in the future you would like to have this
          offer again you will need to contact the Service Provider,
          however the cancelled requests will remain cancelled.
        </template>
        <template v-else>
          You cannot undo this action. If in the future you would like to have
          this offer again you will need to contact the Service Provider
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <app-button
          cancel
          secondary
          @click="close"
        >
          Cancel
        </app-button>

        <app-button
          class="ml-4"
          @click="removeOffer"
        >
          Remove
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import offerService from '@/services/offerService';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    offerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      openRequests: 0
    };
  },
  async created() {
    this.openRequests = await offerService
      .getOfferOpenRequestsAmount(this.offerId);
  },
  methods: {
    async removeOffer() {
      const removedOffer = await offerService.removeCompanyFromOffer(this.offerId);
      if (removedOffer) {
        this.$store.commit('removeRequest', removedOffer.id);
      }
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
p {
  margin: 0.5rem 0;
}
</style>
