<template>
  <v-container class="offers-container">
    <remove-offer-dialog
      v-if="showRemoveDialog"
      :show="showRemoveDialog"
      :offer-id="currentOfferId"
      @close="closeRemoveDialog"
    />
    <v-card class="content-card-padding">
      <v-card-title>
        <v-text-field
          v-model="pagination.search"
          clearable
          append-icon="search"
          label="Search by Title, Service Provider, Phase..."
          single-line
          hide-details
        />
        <v-spacer />
      </v-card-title>
      <v-data-table
        :options.sync="pagination"
        :headers="headers"
        :items="requests"
        :no-data-text="noData"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Offers per page:',
          itemsPerPageOptions: pagination.rowsPerPageItems
        }"
      >
        <template
          v-if="isLoading.offers"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #item="{ item }"
        >
          <tr>
            <td class="text-center">
              <v-icon class="mr-5">
                {{ item.isPublished ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.serviceProviderName }}</td>
            <td>
              <v-avatar
                v-for="phase of item.phases"
                :key="`${item.id}:${phase}`"
                class="mr-1"
                :color="PHASES[phase.toLowerCase()].color"
                size="8"
              />
            </td>
            <td>{{ item.price }}</td>
            <td>{{ item.numberOfRequests }}</td>
            <td>
              <v-menu
                bottom
                left
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in menuOptions"
                    :key="option.name"
                    @click="menuActions(option.name, item)"
                  >
                    <template>
                      <v-list-item-title>{{ option.name }}</v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { TableSkeletonLoader } from '@kickbox/common-admin';
import store from '@/store';
import offerService from '@/services/offerService';
import { phaseUtils } from '@/utils';
import RemoveOfferDialog from './RemoveOfferDialog';

export default {
  components: {
    TableSkeletonLoader,
    RemoveOfferDialog
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 12,
        rowsPerPageItems: [12, 24, 36, { text: 'All', value: -1 }],
        search: '',
      },
      headers: [
        { text: 'Published', value: 'isPublished', width: 115 },
        { text: 'Title', value: 'title', align: 'left', width: 350 },
        { text: 'Service Provider', value: 'serviceProviderName', align: 'left', width: 160 },
        { text: 'Phases', width: 80, sortable: false },
        { text: 'Price', value: 'price', align: 'left', width: 100 },
        { text: '# of Requests', value: 'numberOfRequests', align: 'left', width: 140 },
        { width: 70, align: 'right', sortable: false }
      ],
      menuOptions: [
        { name: 'View' },
        { name: 'Remove' }
      ],
      showRemoveDialog: false,
      currentOfferId: null
    };
  },
  url: {
    pagination: {
      param: 'pagination',
      noHistory: true
    },
  },
  computed: {
    ...mapGetters([
      'company',
      'isLoading',
    ]),

    /**
     * Returns the phase definitions.
     *
     * @static
     */
    PHASES() {
      return phaseUtils.phases;
    },

    noData() {
      return this.isLoading.offers ? 'loading...' : 'No offers.';
    },
    requests() {
      const search = (this.pagination.search || '').toLowerCase();
      const phases = Object.keys(phaseUtils.phases);

      return this.$store.getters.requests.filter((request) => {
        // Apply simple filters
        if (request.title.toLowerCase().includes(search)) return true;
        if (request.serviceProviderName && request.serviceProviderName.toLowerCase()
          .includes(search)) return true;
        if (request.numberOfRequests.toString().toLowerCase().includes(search)) return true;
        if (request.price.toLowerCase().includes(search)) return true;

        // Apply phase filters
        return request.phases.some((phase) => phase.toLowerCase().includes(search));
      }).map((request) => {
        // Sort phases
        request.phases.sort((phase1, phase2) => (
          phases.indexOf(phase1.toLowerCase()) - phases.indexOf(phase2.toLowerCase())
        ));
        return request;
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async () => {
      if (!from.name || (from.name === 'AdminManageViewOffer' && !store.getters.requests.length)) {
        await offerService.getRequestCount();
      }
    });
  },
  methods: {
    menuActions(option, offer) {
      switch (option) {
        case 'View':
          this.$router.push({ path: `offers/${offer.id}` });
          break;
        case 'Remove':
          this.currentOfferId = offer.id;
          this.showRemoveDialog = true;
          break;
        default:
          break;
      }
    },
    closeRemoveDialog() {
      this.currentOfferId = null;
      this.showRemoveDialog = false;
    }
  }
};
</script>

<style scoped>
  .list__tile__title {
    text-align: center;
  }
  table.table tbody td:last-of-type {
    padding: 0;
    text-align: right;
  }
  .list {
    min-width: 80px;
  }
</style>
