var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"offers-container"},[(_vm.showRemoveDialog)?_c('remove-offer-dialog',{attrs:{"show":_vm.showRemoveDialog,"offer-id":_vm.currentOfferId},on:{"close":_vm.closeRemoveDialog}}):_vm._e(),_c('v-card',{staticClass:"content-card-padding"},[_c('v-card-title',[_c('v-text-field',{attrs:{"clearable":"","append-icon":"search","label":"Search by Title, Service Provider, Phase...","single-line":"","hide-details":""},model:{value:(_vm.pagination.search),callback:function ($$v) {_vm.$set(_vm.pagination, "search", $$v)},expression:"pagination.search"}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"table-container",attrs:{"options":_vm.pagination,"headers":_vm.headers,"items":_vm.requests,"no-data-text":_vm.noData,"footer-props":{
        itemsPerPageText: 'Offers per page:',
        itemsPerPageOptions: _vm.pagination.rowsPerPageItems
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(_vm.isLoading.offers)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.headers}})]},proxy:true}:{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(item.isPublished ? 'mdi-check' : 'mdi-close')+" ")])],1),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.serviceProviderName))]),_c('td',_vm._l((item.phases),function(phase){return _c('v-avatar',{key:((item.id) + ":" + phase),staticClass:"mr-1",attrs:{"color":_vm.PHASES[phase.toLowerCase()].color,"size":"8"}})}),1),_c('td',[_vm._v(_vm._s(item.price))]),_c('td',[_vm._v(_vm._s(item.numberOfRequests))]),_c('td',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuOptions),function(option){return _c('v-list-item',{key:option.name,on:{"click":function($event){return _vm.menuActions(option.name, item)}}},[[_c('v-list-item-title',[_vm._v(_vm._s(option.name))])]],2)}),1)],1)],1)])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }